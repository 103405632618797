* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: rgb(250, 250, 250);
}

.Logo {
  color: rgb(18, 107, 155);
}

.Dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 1000px;
}

.DashboardWrapper {
  display: flex;
  padding-right: 1em;
  padding-left: 1em;
  flex-direction: column;
  width: 90%;
}

.GraphWrapper {
  background-color: rgb(253, 255, 255);
  padding: 1em;
  border-radius: 1em;
  box-shadow:  rgba(0, 0, 0, 0.16) 0 1px 4px;
  margin-bottom: 3em;
  margin-right: 1.5em;
  margin-left: 1.5em;
}

.ResultsGraphWrapper {
  background-color: rgb(237, 242, 244);
  padding: 1em;
  border-radius: 1em;
  box-shadow:  rgba(0, 0, 0, 0.16) 0 1px 4px;
  margin-bottom: 3em;
  margin-right: 1.5em;
  margin-left: 1.5em;
  border: 3px solid rgb(18, 107, 155);
}

.Headerbox {
  display: flex;
  padding: 1.8em;
  margin: 1em;
  flex-direction: column;
  text-align: center;
}


.link {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  display: block;
}

.infoText {
  font-size: 0.9em;
  margin: 0;
}

.DashboardHeader {
  margin-left: 1.5em;
  margin-bottom: 0.4em;
  font-size: 0.9em;

}

.Form {
  flex-direction: column;
}

.FormRow {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 0.1em;
  padding: 0.3em;
}

.LastFormRow {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.3em;
}

.GestFormRow {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.1em;
}

.FormInput {
  display: flex;
  width: 100%;
  align-items: center;
  height: 2.5em;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
  overflow: hidden;
  margin: 0.3em;
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
  max-width: 100%;
}

.FormLabel {
  margin-right: 0.5em;
}

.GestFormLabel {
  width: 30%;
  margin-right: 0.5em;
}

.FormColumnL {
  justify-content: start;
  width: 30%;
  height: 100%;
  margin-right: 1em;
}

.FormColumnR {
  flex-direction: column;
  justify-content: left;
  display: flex;
  flex: 1;
  padding: 0.3em;
  align-items: center;
  
}

/* Radio button */
.radio-group {
  display: flex;
  align-items: center;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
  overflow: hidden;
  height: 2.2em;
  margin: 0.3em;
  width: 100%;
}

input[type="radio"] {
  display: none;
}

.radiobutton-label {
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 1em;
  background-color: #f0f0f0;
  font-size: 13px;
}

.radiobutton-label:hover {
  background-color: #e0e0e0;
}

.no {
  border-right: 1px solid silver;
}

input[type="radio"]:checked + .radiobutton-label {
  background-color: rgb(18, 107, 155);
  color: #fff;
}

/* Results */
.results {
  text-align: center;
}

/* disclaimer */

.disclaimer {
  text-align: center;
  color: #6f6e6e;
  font-size: 0.75em;
  max-width: 900px;
  padding-left: 4em;
  padding-right: 4em;
  margin-bottom: 3em;
}

/* test */
.tooltip {
  position: relative;
  display: inline-block;
  font-size: 16px;
  box-sizing: border-box;
  max-width: 100%;
}

.tooltip:hover {
  color: rgb(104, 103, 103);
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(255, 255, 255);
  border-radius: 0.5em;
  padding: 0.5em 1em;
  width: 20em;
  font-size: 12px;
  top: -5px;
  left: 105%;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* error-text */
.error-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error-text {
  font-size: 0.7em;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .DashboardWrapper {
    width: 98%;
  }

  .GraphWrapper {
    margin-bottom: 3em;
    margin-right: 0;
    margin-left: 0;
  }

  .Headerbox {
    margin: 0em;
  }


}
