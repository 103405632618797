html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Arial", sans-serif;
}
